<template>
  <IOdometer
    class="iOdometer"
    :value="number"
  />
</template>

<script>
  import IOdometer from 'vue-odometer';
  import 'odometer/themes/odometer-theme-default.css';
  export default {
    name: "NumberAnimation",
    props: ['num', 'showNumbers'],
    data() {
      return {
        number: 0
      }
    },
    components: {
      IOdometer
    },
    watch: {
      showNumbers: {
        handler() {
          if (this.showNumbers) {
            this.number = this.num;
          }
        }
      }
    },
  }
</script>

<style scoped>
  .iOdometer {
    font-size: 2rem;
  }
</style>
