<template>
  <div style="height: 713px" class="loading" v-if="mission_data === undefined && !mission_error">
    <Loading />
  </div>
  <section class="fun-facts-area ptb-30" v-else-if="mission_data" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <div class="container">
      <div class="section-title">
        <h2>{{mission_data.data[0].heading}}</h2>
        <p class="white-space-pre-wrap" v-html="mission_data.data[0].description"></p>
      </div>

      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-6" v-for="i in [1,2,3,4]" :key="i">
          <div class="row justify-content-center justify-content-lg-start">
            <div class="col-12 single-fun-fact">
              <div class="icon" :class="{'loading-img': typeof mission_data.data[0][`counter${i}_icon`] === 'number'}">
                <img v-if="typeof mission_data.data[0][`counter${i}_icon`] !== 'number'" :src="mission_data.data[0][`counter${i}_icon`]" alt="">
                <img src="../assets/img/logo-circle.png" alt="" v-if="mission_data.data[0][`counter${i}_icon`] === 'default'">
              </div>
              <DefaultImage :id="mission_data.data[0][`counter${i}_icon`]" :isHomePage="true" />
              <h3>
                <NumberAnimation :num="mission_data.data[0][`counter${i}`]" :showNumbers="show" />
              </h3>
              <p>{{mission_data.data[0][`counter${i}_text`]}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import api from "../services/api";
  import useSWRV from "swrv";
  import Loading from "./Loading";
  import DefaultImage from "./DefaultImage";
  import NumberAnimation from "./NumberAnimation";

  export default {
    name: "FunFactsArea",
    props: ['show'],
    components: {
      NumberAnimation,
      DefaultImage,
      Loading
    },
    data() {
      return {
        num: 1000,
        numberCounter: 0,
      };
    },
    setup() {
      let {
        data: mission_data,
        error: mission_error,
        isValidating: mission_isValidating
      } = useSWRV(() => `items/counter?status=published`, api.fetcher);

      return {
        mission_data,
        mission_error,
        mission_isValidating,
      }
    },
    methods: {
      theFormat(value) {
        return Math.floor(value)
      },
      completed() {
        console.log('Animation ends!');
        this.show = true
      },
      kFormatter(value) {
        if (value >= 1000) {
          var suffixes   = ["", "k", "m", "b", "t"];
          var suffixNum  = Math.floor(("" + value).length / 3);
          var shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2));
          if (shortValue % 1 !== 0) {
            shortValue = shortValue.toFixed(1);
          }
          return shortValue + suffixes[suffixNum] + '+';
        } else {
          return value
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .ptb-30 {
    padding:30px 0 ;
  }

  .section-title {
    margin:10px;
  }

  .section-title h2 {
    font-size: 35px;
  }

  .single-fun-fact {
    padding: 0;

    p {
      font-size: 1.1rem;
    }
  }

  .single-fun-fact .icon {
    width: 123px;
    // height: 153px;
    margin: 0 auto;

    &.loading-img {
      background-image: url("../assets/img/loading-img.gif");
      background-size: 50px;
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      width: 80%;
      height: 80%;
      object-fit: cover;
    }
  }

  @media (max-width: 550px) {
      .m-p-30 {
        padding-bottom: 30px;
      }
    }

</style>
